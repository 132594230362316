.grid-component {
  &.grid-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 -10px;
    @media screen and (max-width: 600px) {
      grid-template-columns: 100%;
    }
    @media screen and (min-width: 1500px) {
      grid-template-columns: 25% 25% 25% 25%;
    }
  }

  .grid-block {
    margin: 10px 10px 30px 10px;
    > * {
      max-width: 100%;
    }
    &.clickable {
      cursor: pointer;
    }
    &.clickable:hover {
      opacity: 0.8;
      .description {
        color: brown;
      }
    }
    .see-more {
      color: #8c8989;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .grid-block.center, .center .description {
    text-align: center;
  }
}
