@media print {
  .header {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
  }
  .about-text, .menu, .contact-info  {
    display: none;
  }
  .header-text {
    padding: 30px 30px 0 30px;
  }
  .header .avatar {
    display: block;
    z-index: 1000;
    position: absolute;
    top: 10px;
    right: 30px;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  .content {
    max-height: 100%;
  }
  .print-no-wrap {
    break-inside: avoid;
  }
}