body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
}

a.hide-link-style {
  text-decoration: none;
  color: black;
  outline: black;
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.text-lint {
  color: brown;
}
a:focus, a:active {
  color: #8C8989;
}
a:hover {
  opacity: 0.7;
}
b {
  font-weight: 500;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

p, li {
  font-size: 14px;
  font-weight: lighter;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.4;
}
ul {
  padding-left: 15px;
}
