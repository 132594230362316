.cv {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.cv-skills-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-bottom: 60px;
}
.skills-list {
  width: 50%;
  max-width: 50%;
  min-width: 50%;
}
.skills-list .skills-title {
  font-weight: normal;
  margin-bottom: 5px;
}
.skills-list .skill {
  font-weight: lighter;
  font-size: 14px;
  margin: 0;
}
.cv-button.button {
  width: 100%;
  margin: 0;
}
.experience {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  max-width: 700px;
  margin: 0 auto;
}
.experience-block {
  width: 50%;
  max-width: 50%;
  min-width: 50%;
}
.experience-block h4,
.experience-block p,
.experience-block ul {
  margin: 0;
}

.experience-block h4 {
  margin-top: 10px;
}
.cv .project-name {
  margin: 25px 0 0;
  color: brown;
}
