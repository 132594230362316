.description-section {
  display: flex;
  height: 200px;
}

.description-section .flexible-block {
  flex: 1 1;
  text-align: left;
  padding-left: 30px;
}
h3.flexible-block {
  margin-bottom: 5px;
}
.name-img {
  width: 330px;
  display: block;
  background-image: url("/stickers.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.name-img::before {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  background-image: url(/stickers/panic.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -10px;
  right: 65px;
}
.cute-girl .name-img::before {
  background-image: url(/cute-girl/sticker-16.png);
}
.sticker {
  min-width: 150px;
  height: 150px;
  display: flex;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 10px;
}
.examples {
  display: flex;
  /*justify-content: center;*/
  flex-wrap: wrap;
  padding-top: 50px;
  flex-wrap: wrap;
  padding-top: 50px;
  width: 100%;
  gap: 12px;
}

.sticker:hover {
  background-color: #b4f5ed;
  position: relative;
  background-blend-mode: color-dodge;
}

.sticker .emoji::before {
  visibility: hidden;
}

.sticker:hover .emoji::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: visible;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 50px;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
}

.emoji.nervous::before {
  content: '😨';
}
.emoji.pizza::before {
  content: '🍕';
}
.emoji.instagram::before {
  content: '💥';
}
.emoji.friend-zone::before {
  content: '❤️';
}
.emoji.flowers::before {
  content: '💐';
}
.emoji.wink::before {
  content: '😉';
}
.emoji.slippers::before {
  content: '👣';
}
.emoji.adidas::before {
  content: '🎽';
}
.emoji.youtube::before {
  content: '📺';
}
.emoji.cat::before {
  content: '😺';
}
.emoji.strong-laugh::before {
  content: '😂';
}
.emoji.muesli::before {
  content: '🍿';
}
.emoji.milk::before {
  content: '🥛';
}
.emoji.sleep::before {
  content: '😴';
}
.emoji.sandwich::before {
  content: '🥪';
}
.emoji.tampon::before {
  content: '🚽';
}
.emoji.panic::before {
  content: '😱';
}
.emoji.fuck::before {
  content: '🖕';
}
.emoji.normal::before {
  content: '😐';
}
.emoji.coffee::before {
  content: '☕️';
}
.emoji.in-love::before {
  content: '😍';
}
.emoji.camera::before {
  content: '📷';
}
.emoji.contact::before {
  content: '👉🦶';
}
.emoji.sneaker::before {
  content: '🦵';
}
.emoji.hands::before {
  content: '🙌';
}
.emoji.sad::before {
  content: '🙁';
}
.emoji.love::before {
  content: '❤️';
}
.emoji.pimply::before {
  content: '🤨';
}
.emoji.feet::before {
  content: '🦵🦵';
}
.emoji.leg::before {
  content: '🦵';
}

// cute girl
.emoji.sticker-1::before {
  content: '😘';
}
.emoji.sticker-2::before {
  content: '😑';
}
.emoji.sticker-3::before {
  content: '😈';
}
.emoji.sticker-4::before {
  content: '🙁';
}
.emoji.sticker-5::before {
  content: '😡';
}
.emoji.sticker-6::before {
  content: '😊️';
}
.emoji.sticker-7::before {
  content: '😐';
}
.emoji.sticker-8::before {
  content: '😍';
}
.emoji.sticker-9::before {
  content: '😴';
}
.emoji.sticker-10::before {
  content: '🙄';
}
.emoji.sticker-11::before {
  content: '😯';
}
.emoji.sticker-12::before {
  content: '😊️';
}
.emoji.sticker-13::before {
  content: '🙁️';
}
.emoji.sticker-14::before {
  content: '🤔';
}
.emoji.sticker-15::before {
  content: '🤨';
}
.emoji.sticker-16::before {
  content: '😊';
}
.emoji.sticker-17::before {
  content: '😀';
}
.emoji.sticker-18::before {
  content: '😬';
}
.emoji.sticker-19::before {
  content: '🙄';
}
.emoji.sticker-20::before {
  content: '😕';
}

@media screen and (max-width: 1030px) {
  .name-img {
    width: 200px;
    &::before {
      width: 50px;
      bottom: 25px;
      right: 45px;
    }
  }
  .examples {
    padding-top: 0;
  }
}
@media screen and (max-width: 780px) {
  .examples {
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .description-section {
    flex-direction: column;
  }
  .name-img {
    height: 143px;
    text-align: center;
    align-self: center;
    background-position: center;
    &::before {
      width: 50px;
      bottom: -65px;
      right: 0;
    }
  }
  .description-section .flexible-block {
    padding: 0;
  }
}
@media screen and (max-width: 395px) {
  .name-img::before {
    width: 40px;
    bottom: -65px;
    right: 15px;
  }
}
@media screen and (max-width: 328px) {
  .name-img::before {
    width: 30px;
    right: 40px;
    top: 0;
  }
}
