.screen, .header-page {
  height: 80vh;
}

.title, .sub-title {
  text-align: center;
}
.title {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 0;
}
.button-section {
  display: flex;
  justify-content: space-between;
  margin: 0 20%;
  flex-wrap: wrap;
}
.button {
  display: inline-flex;
  margin: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 30px;
  border: none;
  background-color: #000;
  color: #fff;
  width: 115px;
  cursor: pointer;
  outline: none;
  font-weight: 600;
}
.button:hover {
  opacity: 0.8;
}
.button:active {
  background-color: #6e6e6e;
}
.show-more {
  width: 100%;
  text-align: right;
}

.text-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-section p {
  width: 50%;
}

.main-block {
  display: flex;
}
.content {
  flex: 1;
  text-align: justify;
  line-height: 1.4;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 0 30px;
  word-break: break-word;
}

.active {
  color: coral;
}

@media screen and (min-height: 1000px) {
  .content {
    max-height: 80vh;
  }
}

@media screen and (max-width: 600px) {
  .header-page {
    background-image: none;
  }
  .header-text h1.name {
    font-size: 2em;
  }
  .header-text .name-description {
    font-size: 1em;
  }
  .text-section p {
    width: 100%;
    padding: 30px;
  }
  .menu {
    z-index: 11;
  }
  .header-text {
    text-align: center;
    z-index: 10;
    background-color: white;
  }
  .about-text {
    display: none;
  }
  .contact-info {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: white;
    bottom: 0;
    z-index: 10;
    max-width: 100%;
  }
  .contact-links a {
    font-size: 14px;
    text-align: center;
  }
  .contact-links a:first-child {
    display: block;
  }

  .content {
    max-height: 100%;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 500px) {
  .header-text {
    text-align: left;
  }
  .header-text h1.name {
    font-size: 1.7em;
  }
  .header-text .name-description {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 360px) {
  .header-text {
    text-align: left;
  }
  .header-text h1.name {
    font-size: 1.4em;
  }
  .header-text .name-description {
    font-size: 0.6em;
  }
}
