.test-work {
  .main-work {
    display: flex;
    flex-direction: row;
    align-items: center;
    .img-container {
      max-width: 600px;
    }
    .description-section {
      margin-left: 30px;
      max-width: 300px;
      p {
        margin: 0;
      }
    }
  }

  img {
    width: 100%;
  }
  .container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 -10px;
  }
  .item {
    margin: 10px;
  }
  .img-description {
    font-style: italic;
    text-align: center;
  }
}

.thanks-container {
  text-align: center;
  padding: 50px;
  img {
    width: 50px;
  }
}
